import { useState, useEffect } from "react"
import Activity from "./Activity"

const isToday = (dateString) => {
  const today = new Date()
  const date = new Date(dateString)
  console.log(date, today)
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

const copy = {
  title: isToday("2024-02-11:12:00")
    ? "Happy Birthday Erin!"
    : "Erin's 27th Birthday",
  subtitles: [
    "For your birthday this year I wanted to try something different. I've planned out a weekend of activities for us to do togehter. Each activity will be revealed an hour before we do it, so check back here to see what's next!",
    "Oh and my favorite part! I'll be updating this page with a recap of every activity we do so we can look back on it later."
  ]
}

const App = ({ activities = [], refreshInterval = 15 }) => {
  const [lastRefresh, setLastRefresh] = useState(Date.now())

  // Refresh the latest
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefresh(Date.now())
    }, refreshInterval * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [refreshInterval])

  return (
    <main className="main">
      <div className="header">
        <h1>🥳</h1>
        <h1 className="title">{copy.title}</h1>
        {copy.subtitles.map((subtitle, index) => (
          <p key={index} className="subtitle">
            {subtitle}
          </p>
        ))}
      </div>

      <div className="activities">
        <h3 className="activity-section-title">Saturday, February 10</h3>
        {activities.saturday.map((activity, index) => (
          <Activity key={index} lastRefresh={lastRefresh} {...activity} />
        ))}
      </div>

      <div className="activities">
        <h3 className="activity-section-title">Sunday, February 11</h3>
        {activities.sunday.map((activity, index) => (
          <Activity key={index} lastRefresh={lastRefresh} {...activity} />
        ))}
      </div>
    </main>
  )
}

export default App

const formatTime = (time) => {
  // Get hours and minutes
  var hours = time.getHours()
  var minutes = time.getMinutes()

  // Convert hours to 12-hour format and determine AM/PM
  var ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // Handle midnight (0 hours)

  // Add leading zero to minutes if needed
  minutes = minutes < 10 ? "0" + minutes : minutes

  // Print the time
  return hours + ":" + minutes + ampm
}

const Activity = ({ title, time, location, lastRefresh, alwaysEnabled }) => {
  const isEnabled =
    alwaysEnabled || lastRefresh >= time.getTime() - 60 * 60 * 1000

  return (
    <div className={`activity ${isEnabled ? "" : "disabled"}`.trim()}>
      <h4 className="activity__title">{title}</h4>
      <div className="activity__info">
        <p className="activity__time">{formatTime(time)}</p>
        <p>-</p>
        <p className="activity__location">{location}</p>
      </div>
    </div>
  )
}

export default Activity

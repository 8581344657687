const saturday = [
  {
    title: "🍳 Omlettes & Mimosas",
    location: "Home",
    time: new Date("2024-02-10T11:00"),
    recap: {
      title: "This is the first activity",
      content: ["This is the first activity recap"],
      photo: "https://via.placeholder.com/150",
      video: "https://via.placeholder.com/150"
    }
  },
  {
    title: "🐘 A trip to the zoo",
    location: "Oakland Zoo",
    time: new Date("2024-02-10T12:00")
  },
  {
    title: "🍵 Matcha & Mochi",
    location: "Third Culture Bakery",
    time: new Date("2024-02-10T15:00")
  },
  {
    title: "🛍️ Shopping",
    location: "Sunvalley Shopping Center",
    time: new Date("2024-02-10T15:30")
  },
  {
    title: "🍹 Drinks",
    location: "Room 389",
    time: new Date("2024-02-10T18:30")
  },
  {
    title: "🍽️ Dinner",
    location: "Guru Curry House",
    time: new Date("2024-02-10T19:30")
  },
  {
    title: "🎬 Movie Night",
    location: "Home",
    time: new Date("2024-02-10T21:00")
  }
]

const sunday = [
  {
    title: "🍳 Brunch, Pt 2",
    location: "Home",
    time: new Date("2024-02-11T10:00")
  },
  {
    title: "💐 Build a Bouquet",
    location: "Piedmont",
    time: new Date("2024-02-11T12:00")
  },
  {
    title: "🎮 Games!",
    location: "Home",
    time: new Date("2024-02-11T15:00"),
    alwaysEnabled: true
  }
]

const activities = {
  saturday,
  sunday
}

export default activities
